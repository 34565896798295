export const getImageUrl = (imageUrl?: string) => {
  let url = imageUrl
  if (!imageUrl) {
    return undefined
  }
  if (process.env.NEXT_PUBLIC_BACK_URL) {
    url = `${process.env.NEXT_PUBLIC_BACK_URL ?? ""}${imageUrl}`
  }
  // on local development, the image is uploaded in uploads folder
  if (!url?.includes("https://") && !url?.includes("/uploads")) {
    url = "https://" + url
  }
  return url
}
