import { defineStyleConfig } from "@chakra-ui/react"

const buttonTheme = defineStyleConfig({
  baseStyle: {},
  variants: {
    brand: {
      bg: "black",
      color: "white",
      _hover: {
        color: "white",
        bg: "gray.700",
        _disabled: {
          bg: "gray.400",
        },
      },
      _disabled: {
        bg: "gray.400",
      },
      fontFamily: "GTMaru",
      fontWeight: "light",
      lineHeight: "8px",
    },
  },
})

export default buttonTheme
