import LogoSvg from "@/svg/logo"
import { ImageProps } from "@chakra-ui/react"
import Link from "next/link"

const Logo = (props: ImageProps & { link?: string }) => {
  const logo = <LogoSvg {...props} />

  if (!Link) {
    return logo
  }

  return <Link href="/">{logo}</Link>
}

export default Logo
