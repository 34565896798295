import localFont from "@next/font/local"

const GTAlpina = localFont({
  src: [
    { path: "./GTAplina/GT-Alpina-Standard-Regular.woff2", style: "normal" },
    {
      path: "./GTAplina/GT-Alpina-Standard-Regular-Italic.woff2",
      style: "italic",
    },
  ],
})

const GTMaru = localFont({
  src: [
    {
      path: "./GTMaru/GT-Maru-Regular.woff2",
      style: "normal",
      weight: "400",
    },
    {
      path: "./GTMaru/GT-Maru-Regular-Oblique.woff2",
      style: "oblique",
      weight: "400",
    },
    {
      path: "./GTMaru/GT-Maru-Medium-Oblique.woff2",
      style: "oblique",
      weight: "700",
    },
    {
      path: "./GTMaru/GT-Maru-Medium.woff2",
      style: "normal",
      weight: "700",
    },
  ],
})

export const fonts = {
  GTMaru: GTMaru.style.fontFamily,
  GTAlpina: GTAlpina.style.fontFamily,
}
