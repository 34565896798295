import { Button, Flex, Icon, IconButton, Text } from "@chakra-ui/react"
import Link from "next/link"
import { useRouter } from "next/router"

const NavigationButton = ({
  title,
  content,
  icon,
  disabled,
  link,
  color = "black",
  type = "icon",
  onClick,
  isMobile,
  blank,
}: {
  disabled?: boolean
  title: JSX.Element
  content?: string
  icon?: any
  link?: string
  type?: "icon" | "text"
  color?: string
  onClick?: () => void
  tooltip?: boolean
  isMobile?: boolean
  blank?: boolean
}) => {
  const router = useRouter()
  const isSelected = router?.pathname === link

  const getButton = () => {
    if (type === "icon") {
      return (
        //@ts-ignore
        <IconButton
          icon={
            <Icon
              as={icon}
              boxSize="25px"
              fill={isSelected ? "black" : undefined}
              stroke={disabled ? "gray.400" : undefined}
            />
          }
          aria-label={title}
          variant="link"
          color={color}
        />
      )
    }

    return (
      <Button
        disabled={disabled}
        color={color}
        variant="link"
        leftIcon={
          icon ? (
            <Icon
              as={icon}
              boxSize="25px"
              fill={isSelected ? "black" : undefined}
            />
          ) : undefined
        }
        fontFamily="GTMaru"
        onClick={onClick}
        wordBreak="break-word"
        whiteSpace="normal"
        textAlign="left"
      >
        {title}
      </Button>
    )
  }
  let button = getButton()

  if (content) {
    button = (
      <Flex
        height="100%"
        flex="1"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent={isMobile ? "space-between" : "flex-start"}
        _hover={{ bg: "surface" }}
        transition="background 0.2s ease-in-out"
        bg={isMobile || !isSelected ? "white" : "surface"}
        padding={isMobile ? 0 : 2}
      >
        {button}
        <Text
          fontFamily="GTMaru"
          color={disabled ? "gray.400" : "black"}
          style={{ fontSize: isMobile ? "0.7rem" : "0.85rem" }}
        >
          {content}
        </Text>
      </Flex>
    )
  }

  if (link && !disabled) {
    if (blank) {
      button = (
        <a target="_blank" href={link} rel="noreferrer">
          {button}
        </a>
      )
    } else {
      button = <Link href={link}>{button}</Link>
    }
  }

  return button
}

export default NavigationButton
