import { desktopThreshold } from "@/utils/theme"
import { Box, Flex, useMediaQuery } from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import { ReactNode, createRef, useEffect } from "react"
import MainLoader from "./MainLoader"
import ToolBar from "../Navigation/ToolBar"
import NavigationBar from "../Navigation/NavigationBar"
import { use100vh } from "react-div-100vh"
import { useRouter } from "next/router"

const Layout = ({ children }: { children: ReactNode }) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)
  const { pathname } = useRouter()
  const scrollableRef = createRef<HTMLDivElement>()

  const { status } = useSession()
  const isLoggedIn = status === "authenticated"
  const isLoading = status === "loading"

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo(0, 0)
    }
  }, [pathname, scrollableRef])

  const getMainContent = () => {
    if (!isLoggedIn) {
      return children
    }

    return (
      <Flex height="100%" flexDir={isDesktop ? "row" : "column"}>
        {isDesktop && <NavigationBar />}
        <Box
          ref={scrollableRef}
          width="100%"
          height="100%"
          paddingBottom={isDesktop ? 4 : "80px"}
          overflowY="auto"
          overflowX="hidden"
        >
          {children}
        </Box>
        {!isDesktop && <NavigationBar isMobile />}
      </Flex>
    )
  }

  const height = use100vh() ?? "100dvh"

  if (isLoading) {
    return <MainLoader />
  }

  return (
    <Box bg="secondary.light">
      <Box width="100%">
        <ToolBar />
      </Box>
      <Box height={`calc(${height}px - 60px)`} width="100%">
        {getMainContent()}
      </Box>
    </Box>
  )
}

export default Layout
