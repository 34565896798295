const Home = (props: any) => {
  return (
    <svg
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.9101 32.17V12.24L13.8801 0.849998L0.850098 12.24V32.18H9.0201V16.51H18.7401V32.18H26.9101V32.17Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  )
}

export default Home
