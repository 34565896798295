import { Button, Flex, Text } from "@chakra-ui/react"
import Link from "next/link"
import { useTranslation } from "next-i18next"

const AuthActions = () => {
  const { t } = useTranslation()
  return (
    <>
      <Link href="/auth/login">
        <Flex
          alignItems="center"
          gap={{ base: 0, sm: 2 }}
          flexDirection={{ base: "column", sm: "row" }}
          fontFamily="GTMaru"
        >
          <Text>{t("home.already_registered")}</Text>
          <Text textDecoration="underline"> {t("home.login")}</Text>
        </Flex>
      </Link>

      <Link href="/auth/register">
        <Button variant="brand" fontWeight="light">
          {t("home.create_account")}
        </Button>
      </Link>
    </>
  )
}

export default AuthActions
