const Wishlist = (props: any) => {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.8316 8.79311C30.9017 4.64368 27.537 1.2184 23.3194 1.14943C20.0482 1.09196 17.1157 3.09196 16.0058 6.12644C14.9076 3.14943 12.0452 1.16092 8.83238 1.14943C4.57973 1.18391 1.1449 4.59771 1.17995 8.79311C1.17995 16.9885 16.0058 26.8506 16.0058 26.8506C16.0058 26.8506 30.8316 16.8966 30.8316 8.79311V8.79311Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  )
}

export default Wishlist
