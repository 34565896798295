import client from "@/api/client"
import { useSession } from "next-auth/react"
import React, { ReactNode, useEffect, useState } from "react"

export const AccountContext = React.createContext<{
  user: any
  setAccountInfo: (data: any) => void
}>(null!)

export const AccountProvider = ({ children }: { children: ReactNode }) => {
  const { data: session } = useSession()
  const [accountInfo, setAccountInfo] = useState<any>()

  const loadUser = async () => {
    const response = await client.users.getUsers({
      // @ts-ignore
      query: {
        "populate[memberships][populate][group]": "id",
        "populate[picture]": "*",
      },
    })
    setAccountInfo(response?.data)
  }

  useEffect(() => {
    if (session) {
      loadUser()
    }
  }, [session])

  return (
    <AccountContext.Provider value={{ user: accountInfo, setAccountInfo }}>
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider
