import { AccountContext } from "../contexts/account-context"
import { useContext } from "react"

export default function useAccountContext() {
  const context = useContext(AccountContext)

  if (!context) {
    throw new Error("useAccountContext must be used within a AccountProvider")
  }

  return context
}
