import { Session } from "next-auth"
import { Api } from "../typings/api"
import { getSession, signOut } from "next-auth/react"

const SESSION_CHECK_INTERVAL = 1000 * 10 // 10 seconds
let lastSessionCheck: number
let session: Session | null
let tokenPromise: Promise<string | undefined> | null = null

export const setInitialSession = (initialSession: Session) => {
  session = initialSession
  lastSessionCheck = Date.now()
}

export const getToken = async () => {
  if (!tokenPromise || Date.now() - lastSessionCheck > SESSION_CHECK_INTERVAL) {
    tokenPromise = (async () => {
      session = await getSession()
      lastSessionCheck = Date.now()

      return session?.jwt
    })()
  }

  return tokenPromise
}

export const client = new Api({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

client.instance.interceptors.request.use(
  async (config) => {
    const token = await getToken()

    if (token) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

client.instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    //Token is expired make user login
    if (error?.response?.status === 401) {
      signOut({
        callbackUrl: error?.config?.url
          ? "/auth/login?redirect=" + error?.config?.url
          : "/auth/login",
      })
    }

    // eslint-disable-next-line
    console.error(error)
    return Promise.reject(error)
  }
)

export default client
