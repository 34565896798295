import { defineStyleConfig } from "@chakra-ui/react"

const iconButtonTheme = defineStyleConfig({
  baseStyle: {},
  variants: {
    brand: {
      bg: "black",
      color: "white",
      _hover: {
        color: "gray.50",
        bg: "gray.400",
      },
      fontFamily: "GTMaru",
      fontWeight: "light",
    },
  },
})

export default iconButtonTheme
