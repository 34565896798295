const Book = (props: any) => {
  return (
    <svg
      width="39"
      height="31"
      viewBox="0 0 39 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.4701 29.93C11.7501 26.75 5.5701 26.75 0.850098 29.93V7.47001C5.5701 4.29001 11.7501 4.29001 16.4701 7.47001"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        d="M23.0702 5.43001C20.7002 5.35001 18.3802 6.07001 16.4702 7.48001V29.94C21.1902 26.76 27.3702 26.76 32.0902 29.94V16.96"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
      <path
        d="M26.6902 6.35001H37.6902"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        {...props}
      />
      <path
        d="M32.1902 0.850006V11.85"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        {...props}
      />
    </svg>
  )
}

export default Book
