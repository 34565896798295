import { avatarAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys)

const baseStyle = definePartsStyle({
  excessLabel: {
    bg: "secondary.medium",
  },
})

export const avatarTheme = defineMultiStyleConfig({ baseStyle })
