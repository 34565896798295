import Book from "@/svg/book"
import Home from "@/svg/home"
import Library from "@/svg/library"
import Wishlist from "@/svg/wishlist"
import {
  Box,
  Stack,
  VStack,
  Text,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
} from "@chakra-ui/react"
import { Translation, useTranslation } from "next-i18next"
import NavigationButton from "./NavigationButton"
import UserAvatarButton from "../UserAvatar/UserAvatarButton"
import useAccountContext from "@/hooks/useAccountContext"
import { getUserName } from "../UserAvatar/UserAvatar"
import { BsThreeDots } from "react-icons/bs"
import { IoLogOutOutline } from "react-icons/io5"
import { burgerMenuItems } from "./BurgerMenu"
import Link from "next/link"
import { signOut } from "next-auth/react"

export const DESKTOP_NAV_WIDTH = "230px"

export const menuItems = [
  {
    title: <Translation>{(t) => t("menu.groups")}</Translation>,
    icon: Home,
    link: "/",
    content: "Home",
  },
  {
    title: <Translation>{(t) => t("menu.library")}</Translation>,
    icon: Library,
    link: "/reviews",
    content: "Biblio",
  },
  {
    title: <Translation>{(t) => t("menu.add")}</Translation>,
    icon: Book,
    link: "/reviews/create",
    content: "Review",
  },
  {
    title: <Translation>{(t) => t("menu.wishlist")}</Translation>,
    icon: Wishlist,
    link: "/wishlist",
    content: "Wishlist",
  },
]

const NavigationBar = ({ isMobile }: { isMobile?: boolean }) => {
  const { user } = useAccountContext()
  const name = getUserName(user)
  const { t } = useTranslation()

  const items = menuItems.map((item, i) => (
    <NavigationButton key={i} {...item} type="icon" isMobile={isMobile} />
  ))

  if (isMobile) {
    return (
      <Box
        position="sticky"
        bottom={0}
        width="100%"
        bg="white"
        zIndex={10}
        padding={0}
      >
        <Stack
          flexDir="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={0}
          maxWidth="400px"
          margin="0 auto"
          padding={2}
          paddingTop={3}
        >
          {items}
          <VStack alignSelf="start">
            <UserAvatarButton tooltip height="25px" width="25px" isMobile />
            <Text fontFamily="GTMaru" style={{ fontSize: "0.7rem" }}>
              Profil
            </Text>
          </VStack>
        </Stack>
      </Box>
    )
  }

  return (
    <Box
      height="100%"
      shadow="lg"
      bg="white"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      borderRightWidth={1}
      borderRightColor="surface"
    >
      <Stack mt={4} spacing={2} padding={4} width={DESKTOP_NAV_WIDTH}>
        {items}
      </Stack>
      <Flex
        marginTop="auto"
        pl={4}
        pr={6}
        mb={10}
        justifyContent="space-between"
        alignItems="center"
      >
        <Link href="/account">
          <Flex
            alignItems="center"
            gap={2}
            _hover={{ bg: "surface" }}
            pr={4}
            pl={2}
            py={2}
            rounded="lg"
          >
            <UserAvatarButton tooltip={false} size="sm" />
            <Text noOfLines={1} fontSize="sm">
              {name}
            </Text>
          </Flex>
        </Link>
        <Menu>
          <MenuButton
            as={IconButton}
            _hover={{ bg: "black", color: "white", borderColor: "black" }}
            _active={{ bg: "black", color: "white", borderColor: "black" }}
            rounded="full"
            aria-label="menu"
            icon={<BsThreeDots />}
            size="sm"
            variant="outline"
            transition="none"
          ></MenuButton>
          <MenuList fontSize="sm" zIndex={1000}>
            {burgerMenuItems.map((item, i) => (
              <MenuItem
                key={i}
                as={Link}
                href={item.link || ""}
                _hover={{
                  bg: "black",
                  color: "white",
                  textDecoration: "none",
                }}
                transition="none"
                _focus={{
                  bg: "surface",
                }}
              >
                {item.title}
              </MenuItem>
            ))}
            <MenuDivider />
            <MenuItem
              onClick={() => {
                signOut({ redirect: true, callbackUrl: "/" })
              }}
              _hover={{ bg: "red.100" }}
              display="flex"
              justifyContent="space-between"
              gap={2}
            >
              {t("menu.logout")} <IoLogOutOutline size={20} />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  )
}

export default NavigationBar
