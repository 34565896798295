import { Center, CircularProgress, VStack } from "@chakra-ui/react"
import Logo from "../Logo"
import { use100vh } from "react-div-100vh"

const MainLoader = () => {
  const height = use100vh() ?? "100dvh"
  return (
    <Center h={height}>
      <VStack>
        <Logo width="150px" />
        <CircularProgress isIndeterminate color="black" />
      </VStack>
    </Center>
  )
}

export default MainLoader
