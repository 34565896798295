const Library = (props: any) => {
  return (
    <svg
      width="45"
      height="29"
      viewBox="0 0 45 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.850098 27.82H44.0101"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
      />
      <path
        d="M11.6601 7.59H3.57007V27.82H11.6601V7.59Z"
        {...props}
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M21.7502 0.850006H13.6602V27.83H21.7502V0.850006Z"
        {...props}
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M29.9354 3.98487L23.5564 6.56216L32.1461 27.8225L38.5251 25.2452L29.9354 3.98487Z"
        {...props}
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Library
