import { TooltipProps, Tooltip } from "@chakra-ui/react"
import { ReactNode, useState } from "react"

const CustomTooltip = (props: { children: ReactNode, isMobile?: boolean } & TooltipProps) => {
  const { children, isMobile } = props
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => {
    setIsOpen(true)
    if (isMobile) {
      setTimeout(() => {
        setIsOpen(false)
      }, 500)
    }
  }
  const onClose = () => setIsOpen(false)

  return (
    <Tooltip
      bg="black"
      fontFamily="GTMaru"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      hasArrow
      placement="bottom"
      {...props}
    >
      {children}
    </Tooltip>
  )

}

export default CustomTooltip
