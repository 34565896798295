import { desktopThreshold } from "@/utils/theme"
import { Flex, HStack, useMediaQuery } from "@chakra-ui/react"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import Logo from "../Logo"
import AuthActions from "./AuthActions"
import BurgerMenu from "./BurgerMenu"
import { useTranslation } from "react-i18next"

const ToolBar = () => {
  const router = useRouter()
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)
  const { status } = useSession()
  const { t } = useTranslation()
  const isLoggedIn = status === "authenticated"
  const isLandingPage = router.pathname === "/" && !isLoggedIn

  return (
    <Flex
      bg="primaryColor"
      height="60px"
      fontFamily="GTMaru"
      borderBottomWidth={1}
      borderBottomColor="gray.50"
      justifyContent="space-between"
      pr={4}
    >
      <Flex pl={isDesktop ? 6 : 4} alignItems="center" position="relative">
        <Logo width="100px" height="40px" />
      </Flex>
      {!isLandingPage && (
        <Flex
          display={{ base: "none", md: "inherit" }}
          alignItems="center"
          fontFamily="GTMaru"
          fontSize="sm"
        >
          {t("seo.decription")}
        </Flex>
      )}
      <Flex>
        {isDesktop && isLandingPage && (
          <HStack marginLeft="auto" spacing={10}>
            <AuthActions />
          </HStack>
        )}
        {(!isLoggedIn || !isDesktop) && <BurgerMenu />}
      </Flex>
    </Flex>
  )
}

export default ToolBar
