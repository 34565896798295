import { QueryClient } from "react-query"

export const POPULATE_PARAMS = {
  //@ts-ignore
  "populate[groupGrades][populate][0]": "group",
  "populate[book]": "*",
  "populate[groups]": "*",
  "populate[illustration]": "*",
  "populate[owner][populate][picture]": "*",
  "populate[tags]": "*",
}

export const customQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (
          error &&
          //@ts-expect-error
          (error?.response?.status === 403 || error?.response?.status === 400)
        ) {
          return false
        }
        return true
      },
    },
  },
})
