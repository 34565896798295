import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { useSession } from "next-auth/react"
import NavigationButton from "./NavigationButton"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Translation } from "next-i18next"
import { signOut } from "next-auth/react"

interface IMenuItems {
  title: JSX.Element
  link?: string
  icon?: any
  onClick?: () => void
  color?: string
  blank?: boolean
}

export const burgerMenuItems: IMenuItems[] = [
  {
    title: <Translation>{(t) => t("menu.confidentiality")}</Translation>,
    link: "/terms/privacy",
  },
  {
    title: <Translation>{(t) => t("menu.terms")}</Translation>,
    link: "/terms/cgu",
  },
  {
    title: <Translation>{(t) => t("menu.legal")}</Translation>,
    link: "/terms/legal",
  },
  {
    title: <Translation>{(t) => t("menu.tutorial")}</Translation>,
    link: "/tutorial",
  },
  {
    title: <Translation>{(t) => t("menu.contact")}</Translation>,
    link: `mailto:${process.env.NEXT_PUBLIC_EMAIL}`,
    blank: true,
  },
]

const burgerMenuAccountItems: IMenuItems[] = [
  {
    title: <Translation>{(t) => t("menu.logout")}</Translation>,
    onClick: async () => {
      await signOut({ callbackUrl: "/" })
      if (!window.ReactNativeWebView) return
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ isLoggedOut: true })
      )
    },
    icon: ExternalLinkIcon,
    color: "red",
  },
]

const BurgerMenu = () => {
  const { status } = useSession()
  const isLoggedIn = status === "authenticated"
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton
        icon={<HamburgerIcon />}
        aria-label="menu"
        onClick={onOpen}
        variant="link"
        color="black"
        size="lg"
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent p={4} paddingTop={12}>
          <DrawerCloseButton />
          <VStack height="100%" justifyContent="space-between">
            <VStack spacing={10} alignItems="flex-start" width="100%">
              {burgerMenuItems.map((item, idx) => {
                return (
                  <NavigationButton
                    key={idx}
                    {...item}
                    type="text"
                    onClick={() => {
                      onClose()
                      item?.onClick && item.onClick()
                    }}
                  />
                )
              })}
            </VStack>
            {isLoggedIn && (
              <VStack spacing={6} alignItems="flex-start" width="100%">
                {burgerMenuAccountItems.map((item, idx) => {
                  return (
                    <NavigationButton
                      key={idx}
                      {...item}
                      type="text"
                      onClick={() => {
                        onClose()
                        item?.onClick && item.onClick()
                      }}
                    />
                  )
                })}
              </VStack>
            )}
          </VStack>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default BurgerMenu
