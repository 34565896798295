const LogoSvg = (props: any) => {
  return (
    <svg
      width="352"
      height="70"
      viewBox="0 0 352 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M183.167 69.5169C181.3 69.5169 179.865 68.9979 178.863 67.9671C177.861 66.9364 177.363 65.5163 177.363 63.7142C177.363 61.5229 178.07 58.3945 179.49 54.3362L193.608 12.5713C194.444 10.1853 194.61 8.44814 194.091 7.35247C193.572 6.25681 192.317 5.64411 190.32 5.51436L186.549 5.22602L187.227 2.5157L202.988 0L205.403 0L186.837 54.9129C185.806 57.9404 185.287 59.7785 185.287 60.42C185.287 61.4508 185.77 61.9698 186.736 61.9698C189.188 61.9698 192.634 58.3945 197.083 51.2367L200.176 52.1089C197.148 58.2936 194.343 62.7411 191.762 65.4514C189.181 68.1546 186.311 69.5097 183.153 69.5097L183.167 69.5169Z"
        fill="black"
      />
      <path
        d="M212.174 69.517C210.436 69.517 209.03 69.0341 207.97 68.0681C206.903 67.1022 206.377 65.6822 206.377 63.8152C206.377 61.9483 206.925 59.6272 208.021 57.0466L218.173 33.3601C219.333 30.5921 219.074 29.2009 217.401 29.2009C215.728 29.2009 213.371 31.7455 210.537 36.8345L207.444 35.8686C211.763 26.3969 216.5 21.6611 221.655 21.6611C225.52 21.6611 227.459 23.564 227.459 27.3628C227.459 29.4244 226.911 31.6806 225.815 34.1314L215.663 57.8179C214.503 60.5931 214.762 61.9771 216.435 61.9771C218.108 61.9771 220.494 59.4326 223.4 54.3363L226.493 55.3022C222.044 64.7739 217.271 69.5098 212.181 69.5098L212.174 69.517ZM231.994 13.1553C230.769 14.3807 229.218 14.9934 227.351 14.9934C225.484 14.9934 223.933 14.3807 222.708 13.1553C221.482 11.9298 220.869 10.3873 220.869 8.5131C220.869 6.63894 221.482 5.09636 222.708 3.87095C223.933 2.64554 225.476 2.03283 227.351 2.03283C229.226 2.03283 230.769 2.64554 231.994 3.87095C233.22 5.09636 233.833 6.64615 233.833 8.5131C233.833 10.3801 233.22 11.9298 231.994 13.1553Z"
        fill="black"
      />
      <path
        d="M245.924 69.9999C243.084 69.9999 240.56 69.4376 238.332 68.3059C236.104 67.1742 234.446 65.7758 233.35 64.1035L250.755 12.5785C251.527 10.1925 251.671 8.45532 251.188 7.35966C250.705 6.264 249.465 5.65129 247.467 5.52154L243.696 5.23321L244.468 2.52289L260.229 0.00718687L262.551 0.00718687L254.137 24.9479C252.075 31.0029 248.369 39.3213 243.019 49.8887H244.468C249.753 39.5159 254.151 32.2139 257.67 27.9898C261.181 23.773 264.909 21.6609 268.838 21.6609C275.025 21.6609 278.118 25.3372 278.118 32.6825C278.118 38.3554 276.524 44.1076 273.33 49.9391C270.136 55.7707 266.012 60.5714 260.95 64.3413C255.889 68.1113 250.878 69.9999 245.917 69.9999H245.924ZM247.179 65.747C250.726 65.747 254.303 63.8944 257.915 60.1894C261.527 56.4843 264.397 52.1953 266.524 47.3297C268.651 42.4641 269.718 38.1608 269.718 34.4269C269.718 30.9452 268.463 29.208 265.947 29.208C262.594 29.208 258.146 33.2519 252.601 41.3396C247.056 49.4274 243.221 56.4699 241.094 62.46C242.644 64.6513 244.67 65.747 247.186 65.747H247.179Z"
        fill="black"
      />
      <path
        d="M283.533 68.6447L293.591 35.0035C294.232 32.7473 294.557 31.2696 294.557 30.556C294.557 29.655 294.103 29.2009 293.201 29.2009C291.586 29.2009 289.366 31.7166 286.532 36.7407L283.439 35.7748C287.823 26.368 292.56 21.661 297.65 21.661C301.385 21.661 303.259 23.463 303.259 27.0744C303.259 28.4944 302.711 30.6497 301.616 33.5547C300.52 36.4524 298.169 41.9307 294.557 49.9896H295.912C302.228 37.6778 307.131 29.691 310.614 26.0148C313.065 23.117 315.509 21.661 317.961 21.661C319.569 21.661 320.975 22.18 322.164 23.2107C323.354 24.2415 323.953 25.7264 323.953 27.6583C323.953 29.5901 323.469 31.3201 322.503 32.6392C321.537 33.9583 320.218 34.6215 318.538 34.6215C317.117 34.6215 316.086 34.1385 315.445 33.1726C315.315 32.978 315.012 32.4013 314.529 31.4354C314.046 30.4695 313.548 29.7631 313.029 29.309C309.417 32.596 305.552 37.9157 301.428 45.261C297.304 52.6062 293.821 60.4056 290.988 68.6591H283.54L283.533 68.6447Z"
        fill="black"
      />
      <path
        d="M330.341 69.517C328.603 69.517 327.197 69.0341 326.137 68.0682C325.07 67.1022 324.544 65.6822 324.544 63.8152C324.544 61.9483 325.092 59.6272 326.188 57.0466L336.34 33.3601C337.5 30.5922 337.241 29.2009 335.568 29.2009C333.895 29.2009 331.538 31.7455 328.704 36.8345L325.611 35.8686C329.93 26.3969 334.667 21.6611 339.822 21.6611C343.687 21.6611 345.626 23.5641 345.626 27.3628C345.626 29.4244 345.078 31.6806 343.982 34.1314L333.83 57.8179C332.67 60.5931 332.929 61.9771 334.602 61.9771C336.275 61.9771 338.661 59.4326 341.567 54.3363L344.66 55.3022C340.211 64.774 335.438 69.5098 330.348 69.5098L330.341 69.517ZM350.161 13.1553C348.936 14.3807 347.385 14.9934 345.518 14.9934C343.651 14.9934 342.1 14.3807 340.875 13.1553C339.649 11.9298 339.036 10.3873 339.036 8.51311C339.036 6.63895 339.649 5.09637 340.875 3.87096C342.1 2.64554 343.643 2.03284 345.518 2.03284C347.393 2.03284 348.936 2.64554 350.161 3.87096C351.387 5.09637 352 6.64615 352 8.51311C352 10.3801 351.387 11.9298 350.161 13.1553Z"
        fill="black"
      />
      <path
        d="M0 65.5524C0 64.7883 0.180254 63.6566 0.656123 62.4528L16.8862 22.8503C18.9266 17.8189 21.3348 15.4474 25.9349 15.4474C30.5349 15.4474 32.9359 17.8189 34.9836 22.8503L51.2136 62.4528C51.7256 63.6566 51.9058 64.7883 51.9058 65.5524C51.9058 67.996 49.7932 69.9999 47.3851 69.9999C44.9769 69.9999 43.4483 68.9042 42.7129 66.9724L38.7401 56.8736L13.1657 56.8736L9.19293 66.9724C8.42866 68.9042 6.8929 69.9999 4.52076 69.9999C2.14862 69.9999 0 67.996 0 65.5524ZM35.1999 47.8992L25.9709 24.4506L16.7059 47.8992L35.1999 47.8992Z"
        fill="black"
      />
      <path
        d="M58.1858 64.9326V22.1224C58.1858 17.9993 60.7021 15.707 64.1342 15.707C67.3427 15.707 68.8784 17.2785 70.6666 20.5943L86.1323 49.9105L101.598 20.5943C103.458 17.1631 105.03 15.707 108.167 15.707C111.599 15.707 114.115 18.0065 114.115 22.1224L114.115 64.9326C114.115 67.8159 112.11 69.8919 109.407 69.8919C106.703 69.8919 104.734 67.7438 104.734 64.9326L104.734 32.8772L92.297 56.7656C90.2565 60.5571 88.9443 62.1286 86.1323 62.1286C83.3204 62.1286 81.9721 60.5571 79.9676 56.7656L67.5662 32.8772L67.5662 64.9326C67.5662 67.7438 65.5618 69.8919 62.858 69.8919C60.1542 69.8919 58.1858 67.8159 58.1858 64.9326Z"
        fill="black"
      />
      <path
        d="M120.417 65.5525C120.417 64.7884 120.597 63.6567 121.073 62.4529L137.303 22.8505C139.343 17.8191 141.751 15.4475 146.351 15.4475C150.952 15.4475 153.353 17.8191 155.4 22.8505L171.63 62.4529C172.142 63.6567 172.322 64.7884 172.322 65.5525C172.322 67.9961 170.21 70 167.802 70C165.393 70 163.865 68.9043 163.129 66.9725L159.157 56.8737L133.59 56.8737L129.617 66.9725C128.852 68.9043 127.317 70 124.945 70C122.572 70 120.424 67.9961 120.424 65.5525H120.417ZM155.617 47.8993L146.388 24.4507L137.122 47.8993L155.617 47.8993Z"
        fill="black"
      />
    </svg>
  )
}

export default LogoSvg
